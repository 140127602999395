<template>
  <div>
    <!-- select -->
    <!-- Order number -->
    <div class="fl heads">
      <span class="ml number">{{ $t("order.orderNumber") }}</span>
      <el-input v-model="order_no" class="select"></el-input>
    </div>
    <!-- Transaction time -->
    <div class="fl heads time">
      <span>{{ $t("order.transactionTime") }}</span>
      <el-date-picker
        type="date"
        :placeholder="$t('order.selectTime')"
        v-model="start_date"
      >
      </el-date-picker>
      <i class="el-icon-minus"></i>
      <el-date-picker
        type="date"
        :placeholder="$t('order.endTime')"
        v-model="end_date"
      >
      </el-date-picker>
    </div>
    <div class="clear"></div>
    <!-- Order status -->
    <div class="fl heads mt">
      <span class="ml status">{{ $t("order.orderStatus") }}</span>
      <el-select
        v-model="order_status"
        class="select"
        :placeholder="$t('order.ordersStatus')"
      >
        <el-option
          v-for="item in orderStatus"
          :key="item"
          :label="$t('order.' + item)"
          :value="item"
        />
      </el-select>
    </div>
    <!-- query -->
    <el-button class="query" @click="toSearch">{{
      $t("order.search")
    }}</el-button>
    <div class="clear"></div>

    <!-- All orders -->
    <el-tabs
      v-model="activeName"
      @tab-click="handleClick"
      class="tabs"
      value="allOrders"
    >
      <el-tab-pane
        :label="$t('order.allOrders')"
        name="allOrders"
      ></el-tab-pane>
      <!-- <el-tab-pane
        :label="$t('order.confirmationPending')"
        name="confirmationPending"
      ></el-tab-pane> -->
      <el-tab-pane
        :label="$t('order.paymentPending')"
        name="paymentPending"
      ></el-tab-pane>
      <el-tab-pane
        :label="$t('order.toBeShipped')"
        name="orderConfirm"
      ></el-tab-pane>
      <el-tab-pane :label="$t('order.shipped')" name="shipped"></el-tab-pane>
      <el-tab-pane :label="$t('order.received')" name="received"></el-tab-pane>
      <el-tab-pane
        :label="$t('order.afterSales')"
        name="afterSales"
      ></el-tab-pane>

      <el-card class="title">
        <el-row class="mt1">
          <el-col :span="3">
            <span>{{ $t("order.orderNo") }}</span>
          </el-col>
          <el-col :span="2">
            <span>{{ $t("order.picture") }}</span>
          </el-col>
          <el-col :span="5" align="center">
            <span>{{ $t("order.title") }}</span>
          </el-col>
          <el-col :span="2" align="center">
            <span>{{ $t("order.couponDiscount") }}</span>
          </el-col>
          <el-col :span="2" align="center">
            <span>{{ $t("order.deliveryCharge") }}</span>
          </el-col>
          <el-col :span="2" align="center">
            <span>{{ $t("order.grandTotal") }}</span>
          </el-col>

          <el-col :span="3" align="center">
            {{ $t("order.transactionTime") }}
          </el-col>
          <el-col :span="3" align="center">
            <span>{{ $t("order.orderStatus") }}</span>
          </el-col>
          <el-col :span="2" align="center">
            <span>{{ $t("order.operation") }}</span>
          </el-col>
        </el-row>
      </el-card>

      <el-card class="content" v-for="(item, index) in data" :key="index">
        <el-row class="mt1">
          <el-col :span="3" class="mt1">
            <p>{{ item.orderNumber }}</p>
          </el-col>
          <el-col :span="2" v-if="item.productData[0].img">
            <img class="img" :src="item.productData[0].img" />
          </el-col>
          <el-col :span="2" v-if="!item.productData[0].img"> &nbsp; </el-col>
          <el-col :span="5" class="mt" align="center">
            {{ item.productData[0].productName }}
          </el-col>
          <el-col :span="2" class="mt" align="center">
            {{ item.couponDiscount }}
          </el-col>
          <el-col :span="2" class="mt" align="center">
            <span>${{ item.deliveryCharge }}</span>
          </el-col>
          <el-col :span="2" class="mt" align="center">
            <p>${{ item.subTotal }}</p>
          </el-col>
          <el-col :span="3" class="mt" align="center">
            <!-- new Date(item.createdAt).toLocaleString() -->
            {{ item.createdAt }}
          </el-col>
          <el-col :span="3" class="mt" align="center">
            {{ $t("order." + item.status) }}
          </el-col>
          <el-col :span="2" class="mt1" align="center">
            <button
              type="button"
              class="view_btn"
              @click="toDetail(item._id, item.status)"
            >
              {{ $t("order.detail") }}
            </button>
          </el-col>
        </el-row>
      </el-card>

      <div class="pagination-rounded" v-if="totalRows > perPage">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="perPage"
          layout="total, prev, pager, next, jumper"
          :total="totalRows"
          jumper-label="dd"
        >
        </el-pagination>
      </div>
    </el-tabs>
  </div>
</template>

<script>
import { getLists, searchLists } from "../../helper/api";
const moment = require("moment");

export default {
  data() {
    return {
      start_date: "",
      end_date: "",
      order_no: "",
      order_status: "",
      createdAt: "",
      activeName: "allOrders",
      data: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageSizes: [1, 2, 3, 4, 5],
      orderStatus: [
        "allOrders",
        "confirmationPending",
        "paymentPending",
        "orderConfirm",
        "shipped",
        "delivered",
      ],
    };
  },
  created() {
    this.orderLists(this.currentPage, "allOrders");
  },
  methods: {
    handleClick(tab) {
      this.currentPage = 1;
      this.orderLists(this.currentPage, tab.paneName);
      this.activeName = tab.paneName;
    },
    toSearch() {
      let url = "";
      if (this.order_no) url += "orderNo=" + this.order_no;
      if (this.start_date && this.end_date)
        url += "startDate=" + this.start_date + "&endDate=" + this.end_date;
      if (this.order_status && this.order_status != "allOrders")
        url += "status=" + this.order_status;

      searchLists("get", "order", url, this.currentPage, "desc").then((res) => {
        this.data = [];
        this.totalRows = res.data.count;

        res.data.rows.forEach((item) => {
          item.createdAt = moment(item.createdAt).format(" YYYY/MM/DD HH:mm");
          this.data.push(item);
        });
      });
    },
    orderLists(page, type) {
      if (type == "allOrders") {
        getLists("get", "order", page, "desc").then((res) => {
          // console.log(res);
          this.data = [];
          this.totalRows = res.data.count;
          res.data.rows.forEach((item) => {
            // console.log(item, item.createdAt);
            item.createdAt = moment(item.createdAt).format("YYYY/MM/DD HH:mm");
            this.data.push(item);
          });
        });
      } else {
        let url = "status=" + type;
        searchLists("get", "order", url, page, "desc").then((res) => {
          // console.log(res);
          this.data = [];
          this.totalRows = res.data.count;
          res.data.rows.forEach((item) => {
            item.createdAt = moment(item.createdAt).format("YYYY/MM/DD HH:mm");
            this.data.push(item);
          });
        });
      }
    },
    toDetail(id, status) {
      // console.log(id, status);
      this.$router.push({
        path: "/orders/orderDetail?id=" + id,
        query: {
          status,
        },
      });
    },
    handleSizeChange(val) {
      this.PageSize = val;
      this.currentPage = 1;
      this.orderLists(this.currentPage, this.activeName);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.orderLists(this.currentPage, this.activeName);
    },
  },
};
</script>

<style scoped>
.heads {
  font-size: 14px;
  font-weight: 700;
  margin-right: 30px;
}
.time span {
  margin-right: 10px;
}
.select {
  width: 250px;
  margin-left: 10px;
}
.id {
  margin-right: 10px;
}
.query {
  width: 100px;
  line-height: -40px;
  background: #ff0a0a;
  color: #fff;
  margin-top: 20px;
}
.tabs {
  color: #ff0a0a;
  margin-top: 30px;
  margin-left: 20px;
}
.all {
  margin-left: 60px;
}
.title {
  background: #ffdcbc;
  font-size: 13px;
  font-weight: 700;
  line-height: 13px;
}
.content {
  margin-top: 15px;
  font-size: 13px;
  line-height: 13px;
}
.content .img {
  width: 50px;
  height: 50px;
}
.content .view_btn {
  padding: 5px 10px 5px 10px;
  background-color: #ffdcbc;
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px #cccccc;
  cursor: pointer;
}
.content .view_btn:hover {
  background-color: #ee8c31;
  color: #ffffff;
}
.pagination-rounded {
  height: 40px;
  line-height: 40px;
  width: 100%;
  display: block;
  text-align: center;
  padding-top: 10px;
  background-color: #ffffff;
  margin-top: 20px;
  margin-bottom: 30px;
  border-radius: 5px;
}

div /deep/.el-tabs__item:hover {
  color: #ff0a0a;
}
div .el-tabs__active-bar {
  background-color: #ff0a0a;
}
div /deep/.el-tabs__item.is-active {
  color: #ff0a0a;
}
div .el-tabs__item:focus.is-active.is-focus:not(:active){
  box-shadow: none;
}
</style>